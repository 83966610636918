const EVENT_NAMES = {
  pageView: "PageView",
  pageExit: "PageExit",
};

const {
  ANALYTICS_TRACKER_URL,
  ANALYTICS_TRACKER_API_TOKEN,
  ANALYTICS_TRACKER_LOG_IN_CONSOLE,
} = window;

const PLATFORM = "web";
const APP_NAME = `circle.${window.RAILS_ENV}`;
type PropertyValue = string | number | boolean | Date | null | undefined;
const trackEvent = async (
  eventName: string,
  properties: Record<string, PropertyValue>,
) => {
  const url = ANALYTICS_TRACKER_URL;
  const token = ANALYTICS_TRACKER_API_TOKEN;
  const tenantId = (properties.community_id || 0).toString();

  const defaultProperties = {
    platform: PLATFORM,
    ahoy_visitor: window.ANALYTICS_TRACKER_VISITOR,
  };

  const data = {
    event_name: eventName,
    tenant_id: tenantId,
    app_name: APP_NAME,
    triggered_at: new Date().toISOString(),
    properties: { ...defaultProperties, ...properties },
  };

  let body;
  try {
    body = JSON.stringify(data);
  } catch (error) {
    console.error("Error while converting data to JSON", error);
    return;
  }

  if (url && token) {
    // Try sendBeacon first if available
    if (navigator.sendBeacon) {
      const urlObject = new URL(url);
      urlObject.searchParams.set("token", token);
      navigator.sendBeacon(urlObject.toString(), body);
      return;
    }
    // Fall back to fetch if sendBeacon is unavailable or fails
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    await fetch(url, {
      method: "POST",
      headers,
      body,
      keepalive: true,
    });
    return;
  }
  if (ANALYTICS_TRACKER_LOG_IN_CONSOLE === "true") {
    // eslint-disable-next-line no-console -- Log info to console. Needed for testing
    console.info(data);
  }
};

const trackPageView = (properties: Record<string, PropertyValue>) =>
  trackEvent(EVENT_NAMES.pageView, properties);

const trackPageExit = (properties: Record<string, PropertyValue>) =>
  trackEvent(EVENT_NAMES.pageExit, properties);

export const analyticsTrackerApi = {
  trackPageView,
  trackPageExit,
};
