import { absolutePath } from "./absolutePath";

interface SpacesFnProps {
  id?: string | number;
  spaceId?: string | number;
  spaceGroupId?: string | number;
  params?: Record<string, unknown>;
}

export const spaces = {
  index: ({ params = {} }: SpacesFnProps = {}) =>
    absolutePath("spaces", params),
  create: ({ params = {} }: SpacesFnProps = {}) =>
    absolutePath("spaces", params),
  show: ({ id, params = {} }: SpacesFnProps = {}) =>
    absolutePath(`spaces/${id}`, params),
  activeMembersCount: ({ id, params = {} }: SpacesFnProps = {}) =>
    absolutePath(`spaces/${id}/active_members_count`, params),
  update: ({ id, params = {} }: SpacesFnProps = {}) =>
    absolutePath(`spaces/${id}`, params),
  destroy: ({ id, params = {} }: SpacesFnProps = {}) =>
    absolutePath(`spaces/${id}`, params),
  duplicate: ({ id, params = {} }: SpacesFnProps = {}) =>
    absolutePath(`spaces/${id}/duplicate`, params),
  exportMembers: ({ id, params = {} }: SpacesFnProps = {}) =>
    absolutePath(`spaces/${id}/space_members/export`, params),
  maxSpaceLimit: (spaceGroupId: SpacesFnProps["spaceGroupId"]) =>
    absolutePath("spaces/max_space_limit", { space_group_id: spaceGroupId }),
  resetUnreadCount: ({ spaceId }: SpacesFnProps) =>
    `/internal_api/spaces/${spaceId}/reset_unread_count`,
  addMemberToSpace: ({ spaceId, params = {} }: SpacesFnProps) =>
    absolutePath(`spaces/${spaceId}/space_members/bulk_create`, params),
  bookmarks: {
    index: ({ spaceId, params = {} }: SpacesFnProps = {}) =>
      absolutePath(`spaces/${spaceId}/bookmarks`, params),
    create: ({ spaceId, params = {} }: SpacesFnProps = {}) =>
      absolutePath(`spaces/${spaceId}/bookmarks`, params),
    update: ({ spaceId, id, params = {} }: SpacesFnProps = {}) =>
      absolutePath(`spaces/${spaceId}/bookmarks/${id}`, params),
    destroy: ({ spaceId, id, params = {} }: SpacesFnProps = {}) =>
      absolutePath(`spaces/${spaceId}/bookmarks/${id}`, params),
  },
  destroy_welcome_banner_cover: ({ id, params = {} }: SpacesFnProps = {}) =>
    absolutePath(`/spaces/${id}/space_welcome_banners/destroy_cover`, params),
  resetSpaceWelcomeBannerPreferencePath: (id: SpacesFnProps["id"]) =>
    absolutePath(
      `/spaces/${id}/space_welcome_banners/reset_members_preference`,
    ),
};
