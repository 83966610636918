import { absolutePath } from "./absolutePath";

interface CommunityBotFnProps {
  id?: string | number;
  params?: Record<string, unknown>;
}

const emptyArgs = {
  params: {},
};

export const communityBot = {
  snippets: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/snippets", params),
    show: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/snippets/${id}`),
    create: ({ params }: CommunityBotFnProps = emptyArgs) =>
      absolutePath(`community_bot/snippets`, params),
    update: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/snippets/${id}`),
    destroy: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/snippets/${id}`),
  },
  agents: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/profiles", params),
    show: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/profiles/${id}`),
    create: () => absolutePath(`community_bot/profiles`),
    update: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/profiles/${id}`),
    audienceCount: ({ params = {} } = {}) =>
      absolutePath("community_bot/profiles/audience_count", params),
  },
  spaces: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/spaces", params),
    update: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/spaces/${id}`),
  },
  setting: {
    show: () => absolutePath("community_bot/setting"),
    update: () => absolutePath("community_bot/setting"),
  },
};
