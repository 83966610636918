import type { Theme } from "./types";

export const DEFAULT_THEME: Theme = {
  light: {
    ["--brand"]: "#506CF0",
    ["--brand-button-text"]: "#ffffff",
    // Text colors
    ["--color-text-lightest"]: "#909090",
    ["--color-text-light"]: "#717680",
    ["--color-text-default"]: "#545861",
    ["--color-text-dark"]: "#19283a",
    ["--color-text-very-dark"]: "#2B2E33", //gray800 in design
    ["--color-text-darkest"]: "#191b1f",
    ["--color-text-badge"]: "#ffffff",
    ["--color-text-feature-dark"]: "#F7F9FA",
    ["--color-text-feature-light"]: "#E4E7EB",
    ["--color-text-icon-text"]: "#FFFFFF",
    ["--color-community-active-item-text-color"]: "#fff",
    ["--color-community-sidebar-text-on-accent"]: "#fff",
    ["--color-text-selector-active"]: "#2B2E33",
    ["--color-text-date-blue"]: "#506CF0",
    ["--color-text-chip"]: "#4BA479",
    ["--color-text-placeholder"]: "#545859",
    ["--text-brand-bg"]: "#fff",
    ["--color-messaging-info-blue"]: "66 98 240",
    ["--color-circle-button-text"]: "#fff",
    ["--color-stroke-foreground"]: "#2B2E33",
    ["--color-stroke-background"]: "#E4E8EB",
    ["--color-text-link"]: "#2563EB",
    ["--color-text-workflows-icon"]: "#213EBD",
    ["--color-text-disabled"]: "#A1A1AA",
    // 2563EB
    // Background colors
    ["--color-background-primary"]: "#fff",
    ["--color-background-secondary"]: "#f7f9fa",
    ["--color-background-tertiary"]: "#F0F3F5",
    ["--color-background-overlay"]: "#191b1f33",
    ["--color-background-overlay-dark"]: "#E4E7EB",
    ["--color-background-overlay-light"]: "#ffffffcc",
    ["--color-background-badge"]: "#717680",
    ["--color-background-light-blue"]: "#E3F2F9",
    ["--color-background-selector-active"]: "#F7F9FA",
    ["--color-background-avatar-primary"]: "#fff",
    ["--color-background-avatar-secondary"]: "#D2D7DB",
    ["--color-background-disabled"]: "#D2D7DB",
    ["--color-background-dark"]: "#A5A9AD",
    ["--color-background-dark-button"]: "#2B2E33",
    ["--color-background-feature"]: "#22223B",
    ["--color-online-background"]: "#22BB22",
    ["--color-background-banner"]: "#2B2E33",
    ["--color-background-plus-banner"]: "#191B1F",
    ["--color-background-toggle-active"]: "#42464D",
    ["--color-background-toggle-inactive"]: "#A5A9AD",
    ["--color-background-modal-overlay"]: "#00000066",
    ["--color-background-text-editor-modifier"]: "#1a1d21",
    ["--color-background-text-editor-modifier-hover"]: "#2C2F33",
    ["--color-background-text-editor-modifier-active"]: "#444649",
    ["--color-toggle-checked"]: "#42464D",
    ["--color-toggle-unchecked"]: "#A5A9AD",
    ["--color-toggle-circle-checked"]: "#FFFFFF",
    ["--color-toggle-circle-unchecked"]: "#FFFFFF",
    ["--color-toggle-fullscreen"]: "#191B1F",
    ["--color-circle-button-background"]: "#2B2E33",
    ["--color-circle-button-background-hover"]: "#41464E",
    ["--color-ai-start"]: "#506CF0",
    ["--color-ai-end"]: "#AF81EA",
    ["--color-chip-background"]: "#EDF6F1",
    ["--color-search-yellow-background"]: "#ECE6B2",
    ["--color-background-workflows-icon-wrapper"]: "#EDF0FE",
    ["--color-background-workflows-badge"]: "#ECEFFD",
    ["--color-background-xy-flow"]: "#F6F7F9",
    ["--color-background-workflows-v2-error"]: "#FEF2F2",
    // Border colors
    ["--color-border-primary"]: "#e4e7eb",
    ["--color-border-secondary"]: "#717680",
    ["--color-border-tertiary"]: "#fff",
    ["--color-border-dark"]: "#D2D7DB",
    ["--color-border-darkest"]: "#191b1f",
    ["--color-border-light"]: "#9CA3AF",
    ["--color-border-input"]: "#d2d7db",
    ["--color-border-hover"]: "#F0F3F5",
    ["--color-border-selector-inactive"]: "#D2D7DB",
    ["--color-border-disabled"]: "#D2D7DB",
    ["--color-border-circle-button"]: "#2B2E33",
    ["--color-border-circle-button-hover"]: "#41464E",
    ["--color-border-workflows-v2-error"]: "#FCA5A5",
    // Community sidebar & switcher colors
    ["--color-community-sidebar-text"]: "#545861",
    ["--color-community-sidebar-icon"]: "#E4E7EB",
    ["--color-community-sidebar-icon-hover"]: "#E4E7EB",
    ["--color-community-sidebar-badge-text"]: "#ffffff",
    ["--color-community-sidebar-background"]: "#fff",
    ["--color-community-sidebar-active-background"]: "#506CF0",
    ["--color-community-sidebar-hover-background"]: "#f0f3f5",
    ["--color-community-sidebar-badge-background"]: "#e02f2f",
    ["--color-message-highlight-background"]: "#f5a6070f",
    ["--color-community-sidebar-border"]: "#E4E7EB",
    ["--color-community-switcher-new-community-button-icon"]: "#ffffff66",
    ["--color-community-switcher-background"]: "#ffffff",
    ["--color-community-switcher-new-community-button-bg"]: "#ffffff1a",
    // Event
    ["--color-event-sidebar-item"]: "#e4e7eb",
    //Ring colors
    ["--color-ring-primary"]: "#fff",
    ["--color-ring-secondary"]: "#717680",
    // Support widget colors
    ["--color-support-widget-background"]: "#506CF0",
    // Emoji picker colors
    ["--color-background-emoji-picker"]: "255,255,255",
    ["--color-background-emoji-picker-hover"]: "113, 118, 128",
    ["--color-text-emoji"]: "25, 40, 58",
    // Circle v2.1 colors
    ["--color-sidebar-footer-live-indicator"]: "#0F0C35",
    ["--color-community-header-background"]: "#FFFFFF",
    ["--color-community-header-hover-background"]: "#F7F9FA",
    ["--color-community-header-text"]: "#545861",
    ["--color-community-header-active-text"]: "#545861",
    ["--color-community-header-active-background"]: "#f0f3f5",
    // Feedback colors
    ["--color-feedback-error"]: "#DC2626",
    ["--color-border-workflows-icon-wrapper"]: "#CDD4F6",
  },
  dark: {
    ["--brand"]: "#506CF0",
    ["--brand-button-text"]: "#ffffff",
    // Text colors
    ["--color-text-darkest"]: "#F7F9FA",
    ["--color-text-very-dark"]: "#F7F9FA", //gray50 in design
    ["--color-text-dark"]: "#F0F3F5",
    ["--color-text-default"]: "#A5A9AD",
    ["--color-text-light"]: "#A5A9AD",
    ["--color-text-lightest"]: "#A5A9AD",
    ["--color-text-badge"]: "#202226",
    ["--color-text-feature-dark"]: "#F7F9FA",
    ["--color-text-feature-light"]: "#E4E7EB",
    ["--color-text-icon-text"]: "#717680",
    ["--color-community-active-item-text-color"]: "#fff",
    ["--color-community-sidebar-text-on-accent"]: "#fff",
    ["--color-text-selector-active"]: "#F0F3F5",
    ["--color-messaging-info-blue"]: "66 98 240",
    ["--color-circle-button-text"]: "#202226",
    ["--text-brand-bg"]: "#fff",
    ["--color-stroke-foreground"]: "#E4E8EB",
    ["--color-stroke-background"]: "#545859",
    ["--color-text-date-blue"]: "#506CF0",
    ["--color-text-chip"]: "#4BA479",
    ["--color-text-link"]: "#60A5FA",
    ["--color-text-workflows-icon"]: "#213EBD",
    ["--color-text-disabled"]: "#A5A9AD",
    //Placeholder color
    ["--color-text-placeholder"]: "#A5A9AD",
    // Background colors
    ["--color-background-primary"]: "#2B2E33",
    ["--color-background-secondary"]: "#191B1F",
    ["--color-background-tertiary"]: "#42464D",
    ["--color-background-overlay"]: "#191B1F",
    ["--color-background-overlay-dark"]: "#42464D",
    ["--color-background-overlay-light"]: "#000000cc",
    ["--color-background-badge"]: "#D2D7DB",
    ["--color-background-light-blue"]: "#364752",
    ["--color-background-selector-active"]: "#42464D",
    ["--color-background-avatar-primary"]: "#2B2E33",
    ["--color-background-avatar-secondary"]: "#545861",
    ["--color-background-disabled"]: "#717680",
    ["--color-background-dark"]: "#A5A9AD",
    ["--color-background-dark-button"]: "#F7F9FA",
    ["--color-background-plus-banner"]: "#F7F9FA",
    ["--color-background-feature"]: "#22223B",
    ["--color-online-background"]: "#22BB22",
    ["--color-background-banner"]: "#FFFFFF",
    ["--color-background-toggle-active"]: "#A5A9AD",
    ["--color-background-toggle-inactive"]: "#42464D",
    ["--color-background-modal-overlay"]: "#00000066",
    ["--color-background-text-editor-modifier"]: "#1a1d21",
    ["--color-background-text-editor-modifier-hover"]: "#2C2F33",
    ["--color-background-text-editor-modifier-active"]: "#444649",
    ["--color-toggle-checked"]: "#FFFFFF",
    ["--color-toggle-unchecked"]: "#717680",
    ["--color-toggle-circle-checked"]: "#34373D",
    ["--color-toggle-circle-unchecked"]: "#FFFFFF",
    ["--color-toggle-fullscreen"]: "#A5A9AD",
    ["--color-circle-button-background"]: "#fff",
    ["--color-circle-button-background-hover"]: "#e6e6e6",
    ["--color-ai-start"]: "#506CF0",
    ["--color-ai-end"]: "#AF81EA",
    ["--color-chip-background"]: "#EDF6F1",
    ["--color-search-yellow-background"]: "#ECE6B2",
    ["--color-background-workflows-icon-wrapper"]: "#101941",
    ["--color-background-workflows-badge"]: "#ECEFFD",
    ["--color-background-xy-flow"]: "#191B1F",
    ["--color-background-workflows-v2-error"]: "#4B1F1F",
    // Border colors
    ["--color-border-primary"]: "#42464D",
    ["--color-border-secondary"]: "#D2D7DB",
    ["--color-border-tertiary"]: "#2B2E33",
    ["--color-border-dark"]: "#545861",
    ["--color-border-darkest"]: "#F7F9FA",
    ["--color-border-light"]: "#A5A9AD",
    ["--color-border-input"]: "#000",
    ["--color-border-hover"]: "#42464D",
    ["--color-border-selector-inactive"]: "#545861",
    ["--color-border-disabled"]: "#717680",
    ["--color-border-circle-button"]: "#fff",
    ["--color-border-circle-button-hover"]: "#e6e6e6",
    ["--color-border-workflows-icon-wrapper"]: "#333F75",
    ["--color-border-workflows-v2-error"]: "#8B3232",
    // Community sidebar & switcher colors
    ["--color-community-sidebar-text"]: "#E4E7EB",
    ["--color-community-sidebar-icon"]: "#E4E7EB",
    ["--color-community-sidebar-icon-hover"]: "#E4E7EB",
    ["--color-community-sidebar-badge-text"]: "#fff",
    ["--color-community-sidebar-background"]: "#2B2E33",
    ["--color-community-sidebar-active-background"]: "#506CF0",
    ["--color-community-sidebar-hover-background"]: "#42464D",
    ["--color-community-sidebar-badge-background"]: "#e02f2f",
    ["--color-message-highlight-background"]: "#f5a6070f",
    ["--color-community-sidebar-border"]: "#42464D",
    ["--color-community-switcher-new-community-button-icon"]: "#fff",
    ["--color-community-switcher-background"]: "#2B2E33",
    ["--color-community-switcher-new-community-button-bg"]: "#fff",
    // Event
    ["--color-event-sidebar-item"]: "#545861",
    //Ring colors
    ["--color-ring-primary"]: "#2B2E33",
    ["--color-ring-secondary"]: "#D2D7DB",
    // Support widget colors
    ["--color-support-widget-background"]: "#42464D",
    // Emoji picker colors
    ["--color-background-emoji-picker"]: "43, 46, 51",
    ["--color-background-emoji-picker-hover"]: "66, 70, 77",
    ["--color-text-emoji"]: "210, 215, 219",
    // Circle v2.1 colors
    ["--color-sidebar-footer-live-indicator"]: "#42464D",
    ["--color-community-header-background"]: "#2B2E33",
    ["--color-community-header-hover-background"]: "#42464D",
    ["--color-community-header-text"]: "#E4E7EB",
    // Circle v3.0 colors
    ["--color-community-header-active-text"]: "#E4E7EB",
    ["--color-community-header-active-background"]: "#42464D",
    // Feedback colors
    ["--color-feedback-error"]: "#EF4444",
  },
};
