import { absolutePath } from "./absolutePath";

interface CoursesFnProps {
  courseId?: string | number;
  sectionId?: string | number;
  lessonId?: string | number;
  fileId?: string | number;
  spaceMemberId?: string | number;
  params?: Record<string, unknown>;
}

const lessonPath = ({
  courseId,
  sectionId,
  lessonId,
  params = {},
}: CoursesFnProps = {}) =>
  absolutePath(
    `courses/${courseId}/sections/${sectionId}/lessons/${lessonId}`,
    params,
  );

export const courses = {
  adminSummary: ({ courseId, params = {} }: CoursesFnProps) =>
    absolutePath(`courses/${courseId}/stats`, params),
  students: {
    index: ({ courseId, params = {} }: CoursesFnProps) =>
      absolutePath(`courses/${courseId}/students`, params),
    show: ({ courseId, spaceMemberId, params = {} }: CoursesFnProps) =>
      absolutePath(`courses/${courseId}/students/${spaceMemberId}`, params),
  },
  quizAttempts: {
    index: ({ courseId, params = {} }: CoursesFnProps) =>
      absolutePath(`courses/${courseId}/quiz_attempts`, params),
  },
  listSections: ({ courseId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/sections`, params),
  reorder: ({ courseId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/reorders`, params),
  createLesson: ({ courseId, sectionId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/sections/${sectionId}/lessons`, params),
  getLesson: lessonPath,
  createSection: ({ courseId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/sections`, params),
  updateLesson: lessonPath,
  deleteLesson: lessonPath,
  updateSection: ({ courseId, sectionId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/sections/${sectionId}`, params),
  deleteSection: ({ courseId, sectionId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/sections/${sectionId}`, params),
  settings: ({ courseId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/settings`, params),
  listComments: ({ courseId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/lesson_comments`, params),
  lessonProgress: {
    update: ({ courseId, lessonId, params = {} }: CoursesFnProps = {}) =>
      absolutePath(`courses/${courseId}/lessons/${lessonId}/progress`, params),
  },
  lessonFiles: {
    index: ({ courseId, lessonId, params = {} }: CoursesFnProps = {}) =>
      absolutePath(`courses/${courseId}/lessons/${lessonId}/files`, params),
    destroy: ({
      courseId,
      lessonId,
      fileId,
      params = {},
    }: CoursesFnProps = {}) =>
      absolutePath(
        `courses/${courseId}/lessons/${lessonId}/files/${fileId}`,
        params,
      ),
  },
  exports: ({ courseId, params = {} }: CoursesFnProps = {}) =>
    absolutePath(`courses/${courseId}/exports`, params),
};
