import { absolutePath } from "./absolutePath";

export const AccountSubscriptions = {
  update: (
    planId: number,
    planCouponId: number,
    source: Record<string, unknown>,
  ) => {
    const payload = {
      plan_id: planId,
      plan_coupon_id: planCouponId,
      ...(source && { source }),
    };
    return absolutePath("account_subscriptions", payload);
  },
  renew: (planId: number) =>
    absolutePath("account_subscriptions/renew", { plan_id: planId }),
  canDowngrade: (planId: number) =>
    absolutePath("account_subscriptions/can_downgrade", { plan_id: planId }),
  createMarketingHubSubscription: () =>
    absolutePath("account_subscriptions/create_marketing_hub_subscription"),
  cancelMarketingHubSubscription: () =>
    absolutePath("account_subscriptions/cancel_marketing_hub_subscription"),
  reactivateMarketingHubSubscription: () =>
    absolutePath("account_subscriptions/reactivate_marketing_hub_subscription"),
};
